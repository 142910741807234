import React from "react";
import { Container, Table } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import BodyText from "../../components/body-text/body-text";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";

import "./index.css";

const Page = () => {
  return (
    <LayoutInner>
      <SEO title="Privacy Notice" keywords={[`rocksteady`]} />
      <SecondaryNavBar
        product="generic"
        title="Privacy Notice"
        titlePath="/privacy-notice/"
        titleActive={true}
        links={[]}
      />
      <Container>
        <BodyText>
          <h4>Last Updated: 10/10/2023 </h4>

          <h2>1. Who we are and what we do</h2>

          <h3>Who we are </h3>

          <p>
            Rocksteady Music School (&ldquo;Rocksteady&rdquo;, “us&rdquo;,
            “we&rdquo;, “our&rdquo;) is a limited company registered in England
            and Wales under registration number 08111026. Our registered office
            is at Bohunt Manor, Portsmouth Road, Liphook, Hampshire, GU30 7DL.
            We are also registered with the UK supervisory authority, the
            Information Commissioner&rsquo;s Office (“ICO&rdquo;) in relation to
            our processing of Personal Data under registration number ZA367955.
          </p>

          <h3>What we do </h3>

          <p>
            We provide in-school peri tuition music sessions, making learning
            music fun and giving kids the chance to be in a band, perform songs
            they love and learn life skills. We are committed to protecting the
            privacy and security of the Personal Data we process about you and
            the kids we teach.
          </p>

          <p>
            Unless we notify you otherwise, we are the controller of the
            Personal Data we process about you. This means that we decide what
            Personal Data to collect and how to process it.
          </p>

          <p>
            There are occasions where we process personal data as a data
            processor, on the instructions of a data controller. If we are the
            data processor for your personal data, you will need to contact the
            data controller for full details of how your personal data is used.
          </p>

          <h2>2. Purpose of this privacy notice</h2>

          <p>
            The purpose of this privacy notice is to explain what Personal Data
            we collect about you and how we process it. This privacy notice also
            explains your rights, so please read it carefully. If you have any
            questions, you can contact us using the information provided below
            under the &lsquo;How to contact us&rsquo; section.
          </p>

          <h2>3. Who this privacy notice applies to</h2>

          <p>This privacy notice applies to you if: </p>

          <ol>
            <li>You visit our website</li>
            <li>You purchase/use services from us</li>
            <li>You enquire about our products and/or services</li>
            <li>You use our platform</li>
            <li>
              You sign up to receive newsletters and/or other promotional
              communications from us
            </li>
          </ol>

          <p>
            A cookie is a small file that can be placed on your device that
            allows companies to recognise and remember you. When you visit our
            site, the 3rd party services we use may place analytics cookies on
            your device. This helps them collect data about their service and
            helps them display relevant content.
          </p>

          <h2>4. What Personal Data is</h2>

          <p>
            &lsquo;Personal Data&rsquo; means any information from which someone
            can be identified either directly or indirectly. For example, you
            can be identified by your name or an online identifier.
          </p>

          <p>
            &lsquo;Special Category Personal Data&rsquo; is more sensitive
            Personal Data and includes information revealing racial or ethnic
            origin, political opinions, religious or philosophical beliefs,
            trade union membership, genetic data, biometric data for the
            purposes of uniquely identifying someone, data concerning physical
            or mental health or data concerning someone&rsquo;s sex life or
            sexual orientation.
          </p>

          <h2>5. Personal Data we collect </h2>

          <p>
            The type of Personal Data we collect about you will depend on our
            relationship with you. Below is an example of the different
            categories of personal data we may collect and process about you
            depending on your relationship with us.
          </p>

          <h3>Parents/Guardians/Carers </h3>

          <ul>
            <li>Full name</li>
            <li>Postal address</li>
            <li>Phone number</li>
            <li>Email address</li>
            <li>Child&rsquo;s full name</li>
            <li>Child&rsquo;s school</li>
            <li>Child&rsquo;s age</li>
            <li>Relevant medical information regarding your child </li>
            <li>
              Services preferences (such as musical instrument choice, or band
              choice)
            </li>
            <li>
              Child&rsquo;s attendance history and any reasons for absences{" "}
            </li>
            <li>
              Recordings and images of your child taking part in our services
              (where we are permitted to do so via your consent)
            </li>
            <li>
              Password and account details (if you create an account with us)
            </li>
            <li>
              Purchase history with us and your payment details (such as billing
              address and card details) where you provide them to our customers
              services team
            </li>
            <li>
              Social media handle/username details if you engage with us on
              social media
            </li>
            <li>
              Technical information, including the Internet protocol (IP)
              address used to connect your device to the Internet, your login
              information, browser type and version, time zone setting, browser
              plug-in types and versions, device types, operating system
            </li>
            <li>
              Information about your visit to our website, including the full
              Uniform Resource Locators (URL), clickstream to, through and from
              our site (including date and time), products you viewed, searched
              for or purchased, page response times, download errors, length of
              visits to certain pages, page interaction information (such as
              scrolling, clicks, and mouse- overs), methods used to browse the
              website. We also use Google Analytics to analyse how visitors use
              our website via cookies.
            </li>
            <li>Feedback that you give us or our customer services team </li>
            <li>
              Any other information that you choose to give to us via our
              website or to our customer services team
            </li>
          </ul>

          <p>
            If you are a Parent/Guardian/Carer, where we refer to 'your personal
            data' we are referring to the personal data of both you and your
            child/the child under your care.
          </p>

          <h3>Third Party Contacts </h3>

          <ul>
            <li>Full name</li>
            <li>Email address</li>
            <li>Home address</li>
            <li>Organisation you work for</li>
            <li>Technical information (as per above)</li>
            <li>Information about your website visit</li>
            <li>Any other information chosen to provide</li>
          </ul>

          <h2>6. How we collect your Personal Data </h2>

          <p>
            We may collect personal data in a variety of ways, but essentially
            it is when you give it to us, which includes when you:
          </p>

          <h3>Parents/Guardians/Carers </h3>

          <ul>
            <li>Create an account with us</li>
            <li>Access our website</li>
            <li>Ask us to provide you with services and use our services</li>
            <li>Sign up to receive marketing communications from us</li>
            <li>Contact our customer services team</li>
            <li>
              Enter one of our competitions, promotions, or fill in a survey
            </li>
            <li>
              Engage with us on social media (for example by mentioning/tagging
              us or by contacting us directly)
            </li>
            <li>
              Leave comments on our website or interact with other functionality
              where you provide us with data on our website
            </li>
          </ul>

          <p>
            We may also occasionally receive information about your child from
            their school.
          </p>

          <h3>Third Party Contacts </h3>

          <p>We may collect your data in the following ways when you: </p>

          <ul>
            <li>Enter into contractual negotiations with us</li>
            <li>Sign up to our marketing communications</li>
            <li>Contact us</li>
            <li>Attend one of our events</li>
          </ul>

          <h2>
            7. How we use your personal data and our justifications for doing so
          </h2>

          <p>
            Applicable Data Protection legislation requires us to identify
            appropriate lawful bases to process personal data. The table below
            highlights how and why we use your personal data, along with the
            legal justification for doing so.
          </p>

          <h3>Parents/Guardians/Carers </h3>

          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  How we use your personal data
                </Table.HeaderCell>
                <Table.HeaderCell>Lawful basis for processing</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  To provide you with information about the services you have
                  requested or purchased from us.
                </Table.Cell>
                <Table.Cell>Performance of contract </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <p>
                    Use of video footage and images of sessions involving your
                    child to create media for the parent timeline, accessible on
                    the Rocksteady &lsquo;Backstage&rsquo; feature.
                  </p>
                  <p>
                    We may also record sessions involving your child for
                    marketing purposes.
                  </p>
                </Table.Cell>
                <Table.Cell>Your consent </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  To tell you about relevant products or services we offer
                </Table.Cell>
                <Table.Cell>Your consent </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <p>
                    To ensure that financial information provided to us is
                    accurate.
                  </p>
                  <p>
                    Detect, investigate, report, and seek to prevent financial
                    crime or other illegal activity.
                  </p>
                  <p>Managing financial risk to us and our customers.</p>
                </Table.Cell>
                <Table.Cell>
                  Legal obligation and Legitimate Interests
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  To ensure the website is optimised for you and your device.
                </Table.Cell>
                <Table.Cell>
                  Your Consent (please see our section on Cookies for further
                  information)
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  To measure how satisfied our customers are and find out more
                  about the users of our services, to ensure products and
                  services are most likely to interest our customers.
                </Table.Cell>
                <Table.Cell>Legitimate Interests </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  To ensure our services are running effectively and respond to
                  your contact for the purposes of administering our business.
                </Table.Cell>
                <Table.Cell>Legitimate interests </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <h3>Third Party Contacts </h3>

          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  How we use your personal data
                </Table.HeaderCell>
                <Table.HeaderCell>Lawful basis for processing</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  To provide you with information about the services you have
                  requested or purchased from us.
                </Table.Cell>
                <Table.Cell>Performance of contract </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  To engage with you as a service provider, including making
                  queries regarding the services that you provide and for the
                  purposes of managing our contract with you.
                </Table.Cell>
                <Table.Cell>Performance of contract </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  To maintain an internal list of potential suppliers
                </Table.Cell>
                <Table.Cell>Legitimate Interests </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <h2>8. Sharing your Personal Data </h2>

          <p>
            When we provide you with services, we may share your personal data
            for several reasons which are detailed below. Whenever we share your
            personal data, we put safeguards in place which require these other
            organisations to keep your data safe and to ensure that they do not
            use your personal data for their own marketing purposes unless you
            have given us your consent to do so. We will never sell your
            personal data to a third party.
          </p>

          <p>We may share your personal data in the following circumstances:</p>

          <ol>
            <li>
              <strong>To provide our services</strong> – we work with a number
              of trusted service providers who carry out services on our behalf,
              such service providers include website and database hosting
              (Amazon Web Services) and our certificate provider, Trinity
              College.
            </li>
            <li>
              <strong>To comply with legal requests and prevent crime</strong> -
              There may be scenarios where we are subject to a legal obligation
              to disclose or share your personal data, such as with law
              enforcement agencies, regulatory bodies or public authorities in
              order to prevent or detect crime. We will only ever disclose your
              personal data to these third parties to the extent we are required
              to do so by law.
            </li>
            <li>
              <strong>If our Group Structure changes</strong> - We may also
              share your personal data if we choose to sell, transfer, or merge
              parts of our business and/or group, or our assets in the future.
              Or we may seek to acquire other businesses or merge with them.
              During any such process, we may share your data with other
              parties. We will only do this if they agree to keep your data
              safe.
            </li>
          </ol>

          <h2>9. International Transfers </h2>

          <p>
            Your Personal Data may be processed outside of the UK, this is
            because some of the organisations we use to provide our service to
            you are based internationally.
          </p>

          <p>
            We have taken appropriate steps to ensure that the Personal Data
            processed outside the UK has an essentially equivalent level of
            protection to that guaranteed in the UK.
          </p>

          <p>We do this by ensuring that:</p>

          <ul>
            <li>
              Your Personal Data is only processed in a country which the
              Secretary of State has confirmed has an adequate level of
              protection (an adequacy regulation), or
            </li>
            <li>
              We enter into an International Data Transfer Agreement
              (“IDTA&rdquo;) with the receiving organisation and adopt
              supplementary measures, where necessary.
            </li>
          </ul>

          <h2>10. Your rights and how to complain </h2>

          <p>
            You have certain rights in relation to the processing of your
            Personal Data, including to:
          </p>

          <ul className="rightsTable">
            <li>
              <h5>Right to be informed </h5>

              <p>
                You have the right to know what personal data we collect about
                you, how we use it, for what purpose and in accordance with
                which lawful basis, who we share it with and how long we keep
                it. We use our privacy notice to explain this.
              </p>
            </li>
            <li>
              <h5>
                Right of access (commonly known as a “Subject Access
                Request&rdquo;)
              </h5>

              <p>
                You have the right to receive a copy of the Personal Data we
                hold about you.
              </p>
            </li>
            <li>
              <h5>Right to rectification </h5>

              <p>
                You have the right to have any incomplete or inaccurate
                information we hold about you corrected.
              </p>
            </li>
            <li>
              <h5>
                Right to erasure (commonly known as the right to be forgotten)
              </h5>

              <p>You have the right to ask us to delete your Personal Data. </p>
            </li>
            <li>
              <h5>Right to object to processing </h5>

              <p>
                You have the right to object to us processing your Personal
                Data. If you object to us using your Personal Data for marketing
                purposes, we will stop sending you marketing material.
              </p>
            </li>
            <li>
              <h5>Right to restrict processing </h5>

              <p>
                You have the right to restrict our use of your Personal Data.
              </p>
            </li>
            <li>
              <h5>Right to portability </h5>

              <p>
                You have the right to ask us to transfer your Personal Data to
                another party.
              </p>

              <p>
                Automated decision-making. You have the right not to be subject
                to a decision based solely on automated processing which will
                significantly affect you. We do not use automated
                decision-making.
              </p>
            </li>
            <li>
              <h5>Right to withdraw consent </h5>

              <p>
                If you have provided your consent for us to process your
                Personal Data for a specific purpose, you have the right to
                withdraw your consent at any time. If you do withdraw your
                consent, we will no longer process your information for the
                purpose(s) you originally agreed to, unless we are permitted by
                law to do so.
              </p>
            </li>
            <li>
              <h5>Right to lodge a complaint </h5>

              <p>
                You have the right to lodge a complaint with the relevant
                supervisory authority, if you are concerned about the way in
                which we are handling your Personal Data. The supervisory
                authority in the UK is the Information Commissioner&rsquo;s
                Office who can be contacted online at:
              </p>

              <p>
                <a href="https://ico.org.uk/global/contact-us/">
                  ico.org.uk/global/contact-us
                </a>
              </p>

              <p>Or by telephone on 0303 123 1113 </p>
            </li>
          </ul>

          <h3>How to exercise your rights </h3>
          <p>
            You will not usually need to pay a fee to exercise any of the above
            rights. However, we may charge a reasonable fee if your request is
            clearly unfounded or excessive. Alternatively, we may refuse to
            comply with the request in such circumstances.
          </p>

          <p>
            If you wish to exercise your rights, you may contact us using the
            details set out below within the section called &lsquo;How to
            contact us and our Data Protection Officer&rsquo;. We may need to
            request specific information from you to confirm your identity
            before we can process your request. Once in receipt of this, we will
            process your request without undue delay and within one month. In
            some cases, such as with complex requests, it may take us longer
            than this and, if so, we will keep you updated.
          </p>

          <h2>11. Children&rsquo;s Privacy and Safeguarding</h2>

          <p>
            We understand the importance of ensuring children&rsquo;s data is
            kept safe and do all we can to make sure it is not compromised. We
            have security measures and procedures in place should we become
            aware of any compromise to a child&rsquo;s data and will inform
            relevant parties as needed and required under data protection
            legislation.
          </p>

          <p>
            Rocksteady Music School has a statutory and moral duty to safeguard
            and promote the welfare of all children receiving education and
            training with us. More information can be found in our{" "}
            <a href="/safeguarding-policy">Safeguarding Notice</a>.
          </p>

          <h2>12. Recorded Music Sessions</h2>

          <p>
            We record our band sessions in the form of video, audio, and
            photographic images. We do this for the following purposes:
          </p>

          <ul>
            <li>
              Showcasing your child&rsquo;s educational experience with us as
              part of the service we provide
            </li>
            <li>Assisting with lesson planning and development</li>
            <li>Training</li>
          </ul>

          <p>
            We don&rsquo;t record every session or performance piece and where
            we record/photograph sessions for our &lsquo;Backstage&rsquo;
            feature, consent is gathered from parents/guardians/carers.
          </p>
          <p>
            Where schools have enrolled children via our bursary and funded
            schemes, we will not record/take photos unless direct consent has
            been provided to us from a parent/guardian/carer. Any consent
            withdrawals, concerns or complaints will be passed onto the school
            as necessary.
          </p>

          <h2>13. Use of Cookies and Similar Technologies</h2>

          <p>
            Our website utilises cookies and similar technologies. You can find
            out more by viewing our{" "}
            <a href="/cookie-policy/">cookie notice available here</a>.
          </p>

          <h2>14. Security and storage of information </h2>

          <p>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions, and they are subject
            to a duty of confidentiality.
          </p>

          <p>
            If we become aware of any loss, misuse, alteration of personal data
            we will work closely with our IT team, Data Protection Officer (DPO)
            and other parties as necessary to investigate the incident at hand.
            We have put into place the relevant procedure and policies in place
            to investigate, mitigate and report (when needed to relevant
            parties) such instances.
          </p>

          <h2>15. Data Retention</h2>

          <p>
            We will not keep Personal Data longer than is necessary, for the
            purpose or purposes for which they were collected. Retention periods
            will vary according to the types of personal information we hold and
            for what purposes. Rocksteady will take all reasonable steps to
            destroy, or erase from its systems, all Personal Data which is no
            longer required. This does not apply to anonymised data.
          </p>

          <p>
            If you would like more information on our data retention practices,
            please contact us using the details in the &lsquo;Contact Us&rsquo;
            section.
          </p>

          <h2>16. How to contact us and our Data Protection Officer</h2>

          <p>
            If you wish to contact us in relation to this privacy notice or if
            you wish to exercise any of your rights outlined above, please
            contact us as follows:
          </p>

          <p>
            <strong>Rocksteady Music School</strong>
            <br />
            Bohunt Manor, Portsmouth Road, Liphook, Hampshire, GU30 7DL
            <br />
            Phone: 0330 113 0330
            <br />
            Email:{" "}
            <a href="mailto:data@rocksteadymusicschool.com">
              data@rocksteadymusicschool.com
            </a>
          </p>

          <p>
            We have also appointed a Data protection Officer (“DPO&rdquo;). Our
            DPO is Evalian Ltd can be contacted as follows:
          </p>

          <p>
            <strong>Evalian Limited</strong>
            <br />
            West Lodge
            <br />
            Leylands Business Park
            <br />
            Colden Common
            <br />
            Hampshire
            <br />
            SO21 1TH
            <br />
            <a href="https://www.evalian.co.uk">www.evalian.co.uk</a>
            <br />
            Phone: 0333 050 0111
          </p>

          <h2>17. Changes to this privacy notice</h2>

          <p>
            We may update this notice (and any supplemental privacy notice),
            from time to time as shown below. We will notify of the changes
            where required by applicable law to do so.
          </p>

          <p>Last modified 10/10/2023</p>

          <p>
            You can contact us for previous versions of this Privacy Notice.
          </p>
        </BodyText>
      </Container>
    </LayoutInner>
  );
};

export default Page;
